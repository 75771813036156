.large-picture
  display: block
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  &__content
    display: table
    table-layout: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 100%
    margin: auto
    text-align: center
  &__navigation, &__image
    display: table-cell
    vertical-align: middle
  &__navigation
    width: 30%
    &.disabled
      button
        opacity: 0.4
        cursor: default !important
      &:hover
        span
          left: 0 !important
    button
      padding: 10px
      font-family: $secondaryFont
      font-size: 16px
      font-weight: 600
      background: transparent
    span
      display: inline-block
      vertical-align: baseline
      position: relative
      left: 0
      right: 0
      padding: 0 6px
      font-size: 1.3em
      font-weight: 600
      +transition(all 100ms linear)


    &--prev button
      padding-left: 5%
      &:hover span
        left: -10px !important
    &--next button
      padding-right: 5%
      &:hover span
        left: 10px !important
    
  &__image
    opacity: 0
    width: 50%
    background-image: url(../images/spinner.gif)
    background-repeat: no-repeat
    background-position: center center
    +transition(opacity 50ms linear 100ms)
    img
      display: inline-block
      height: 80%

  &__options
    display: block
    position: absolute
    bottom: 0
    left: 0
    right: 0
    padding: 20px 0
    text-align: center
    a, span, button
      display: inline-block
      vertical-align: middle
      margin: 5px
      font-family: $secondaryFont
      font-size: 14px
      font-weight: 600
      background: transparent
    .zoom-image-button
      display: none
      &::before
        content: ""
      


#fb-root
  position: fixed
  z-index: 9999999999999
  top: 0



.loop-icon
  display: block
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  width: 58px
  height: 58px
  z-index: 999999
  opacity: 0
  pointer-events: none

.modal-open .large-image:hover + .loop-icon
  opacity: 1




  
+media-query($xtablet)
  .large-picture
    &__navigation
      button
        font-size: 0
        span
          font-size: 48px
    &__options
      .zoom-image-button
        display: inline-block
