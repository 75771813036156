.download
	display: block
	margin-bottom: 15px
	padding: 0
	&__file
		display: block
	.file
		margin: 5px 0
		&__link
			display: inline-block
			vertical-align: bottom
			color: inherit
		&__title
			display: inline-block
			vertical-align: bottom
			margin-right: 10px
		&__format
			display: inline-block
			vertical-align: bottom
			width: 20px
			height: 25px
			margin-right: 15px
			font-size: 8px
			font-weight: 600
			text-align: center
			text-transform: uppercase
			color: #fff
			background: url(../images/icons/file.png) no-repeat
			line-height: 25px
		&__size
			display: inline-block
			vertical-align: middle
			font-size: 12px
			font-weight: 400
			color: #333
		.download-ico
			display: inline-block
			vertical-align: middle
			margin-left: 10px

+media-query(500px)
	.download
		display: block
		margin-bottom: 20px
		text-align: center
		background: #eee
		.file
			&__link
				padding: 10px