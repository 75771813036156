@include keyframes(fadeOutLeftBig) {
  0% {opacity: 1; transform: translateX(0);}
  35% {opacity: 0.3;}
  100% {opacity: 0; transform: translateX(-1000px) scale(0.5);}
}

@mixin fadeOutLeftBig($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOutLeftBig);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
