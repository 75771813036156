$background: #111
$color: #111

$height-icon: 30px
$width-line: 55px
$height-line: 6px

$transition-time: 0.4s
$rotation: 45deg
$translateY: ($height-icon / 2)
$translateX: 0


.header-mobile
  display: none
  padding: 15px
  position: fixed
  top: 0
  left: 0
  width: 100%
  background: #fff
  z-index: 999999
  +clearfix
  .modal-open &
    display: none
.header-mobile .header-mobile__logo
  display: inline-block
  float: left
  width: 100px
#hamburger-icon
  display: block
  float: right
  width:$width-line
  height:$height-icon
  top: 5px
  right: 30px
  margin: 0
  .line 
    display:block
    background:$color
    width:$width-line
    height:$height-line
    position:absolute
    left:0
    border-radius:($height-line / 2)
    transition: all $transition-time
    -webkit-transition: all $transition-time
    -moz-transition: all $transition-time
    &.line-1 
      top:0
    &.line-2 
      top:50%
    &.line-3 
      top:100%
    
  
  &:hover, &:focus 
    .line-1 
      +transform(translateY($height-line / 2 * -1))
     
    .line-3 
      +transform(translateY($height-line / 2))
      
  
  &.active 
    .line-1 
      +transform(translateY($translateY) translateX($translateX) rotate($rotation))
    
    .line-2 
      opacity:0
    
    .line-3 
      +transform(translateY($translateY * -1) translateX($translateX) rotate($rotation * -1))
    
// .base-view--gallery #hamburger-icon
//   .line
//     background: #fff
//   &.active
//     .line
//       background: #000 !important

+media-query($mobile)
  .header-mobile
    display: block
    
  #hamburger-icon
    display: block