.frame
	display: inline-block
	position: absolute
	top: 5%
	left: 0
	right: 0
	width: 425px
	margin: auto
	&::before, &::after
		content: ""
		position: absolute
		top: auto
		bottom: 100%
		width: 1px
		height: 1000px
		background: rgba(0, 0, 0, 0.1)
		z-index: 5
		box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1)
	&::before
		left: 15%
	&::after
		right: 15%
		
	&__border
		position: relative
		display: block
		border-width: 10px
		border-style: solid
		border-color: darken(#2F2D2D, 10%) darken(#434040, 10%) darken(#2F2D2D, 10%) darken(#434040, 10%)
		box-shadow: 0px 9px 10px 0px #444
		z-index: 10
	&__content
		background: #fff
		padding: 20px
		box-shadow: inset 0px 0px 10px 0px #777
	&__text
		padding: 30px
		padding-top: 230px
		box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1)
		&::after
			content: ""
			position: absolute
			top: -50px
			bottom: 0
			left: 100px
			width: 100%
			margin: auto
			background: url(../images/shine.png) top left no-repeat
			background-size: cover

		h1, h2, h3, h4, p, a, ul, ol
			display: block
			margin: 0
			padding: 0
			min-height: 0
			font-family: $secondaryFont
			text-align: center
			line-height: 1
		h4
			font-size: 44px
			line-height: 0.8
			&.error__title
				padding-right: 20px
				text-align: right

		p
			padding: 20px 0
			font-size: 17px
			font-weight: 300
			letter-spacing: 1px
			line-height: 1.4
			a
				display: inline
				text-decoration: underline
				&:hover
					text-decoration: none
		.error__code
			padding: 0
			font-size: 180px
			font-weight: 800
			letter-spacing: 0px
			line-height: 1

		
	&--error
		.frame__text
			color: #fff
			background: url(../images/error.jpg) top center no-repeat
			background-size: cover
	&--buy
		position: fixed
		display: block
		width: 425px
		height: 610px
		top: 10%
		bottom: 0
		left: 15%
		margin: auto
		right: auto
		.frame__text
			background: url(../images/buy-prints.jpg) top center no-repeat
			background-size: cover
			box-shadow: inset 0px 10px 10px -5px #000
		.frame__content
			padding: 0
		.frame__content, .frame__text, .frame__border
			height: 100%


+media-query($retina)
	.frame
		&--buy
			width: 360px
			height: 515px