@include keyframes(fadeInRightBig) {
  0% {opacity: 0; transform: translateX(1000px) scale(0.5);}
  65% {opacity: 0.4;}
  100% {opacity: 1; transform: translateX(0) scale(1);}
}

@mixin fadeInRightBig($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInRightBig);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
