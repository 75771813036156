@-webkit-keyframes slideOutLeft {
	0% {-webkit-transform: translateX(0);}
	100% {-webkit-transform: translateX(-2000px);}
}

@-moz-keyframes slideOutLeft {
	0% {-moz-transform: translateX(0);}
	100% {-moz-transform: translateX(-2000px);}
}

@-ms-keyframes slideOutLeft {
	0% {-ms-transform: translateX(0);}
	100% {-ms-transform: translateX(-2000px);}
}

@-o-keyframes slideOutLeft {
	0% {-o-transform: translateX(0);}
	100% {-o-transform: translateX(-2000px);}
}

@keyframes slideOutLeft {
	0% {transform: translateX(0);}
	100% {transform: translateX(-2000px);}
}

@mixin slideOutLeft($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(slideOutLeft);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
