.print-info
	display: none
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	margin: auto
	z-index: 99999
	&.fadeIn
		.print-info-box__container
			opacity: 1
			+fadeInDown(200ms)
	&.fadeOut
		.print-info-box__container
			opacity: 1
			+fadeOutDown(200ms)
	&__overlay
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba(0, 0, 0, 0.8)
		z-index: 10
	&__box
		width: 100%
		margin: auto
		max-width: 1060px

	&__container
		display: table
		width: 100%
		height: 100%
		position: relative
		z-index: 20
	
.print-info-box
	display: table-cell
	vertical-align: middle
	&__container
		position: relative
		display: table
		width: 100%
		max-width: 1060px
		margin: 0 auto
		background: #fff
		opacity: 0

	&__image, &__content
		display: table-cell
		vertical-align: top
	&__image
		width: 345px
		height: 100%
		vertical-align: middle
		font-size: 0
		text-align: center
		line-height: 1
		overflow: hidden
		img
			display: inline-block
		
	&__content
		padding: 20px 30px
		.text
			h1, h2, h3, h4, h5,
			p, a, ul, ol
				margin-bottom: 20px
				line-height: 1.4
			h3 span
				font-size: 0.8em
				font-style: italic
			
			p, a, ul, ol
				margin-bottom: 25px
				font-size: 14px
				line-height: 2em

	&__close
		position: absolute
		top: 0
		right: 0
		padding: 8px
		font-size: 24px
		font-weight: 100
		color: #fff
		background: #000
		&:hover
			background: #111

+media-query($tablet)
	.print-info-box
		&__image
			display: none
			