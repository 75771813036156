.text
	display: block
	p, a, ul, ol
		font-family: $baseFont
		font-size: 14px
		font-weight: 300
		line-height: 1.6
	h1, h2, h3, h4, h5, h6
		line-height: 1.2
	h1
		font-size: 42px