@import "vendor/normalize"
@import "vendor/bootstrap-grid"
@import "bourbon"
@import "vendor/animate/animate"

$baseColor: #000
$secondaryColor: #ff0e47

$baseFont: "jaf-bernino-sans", sans-serif
$secondaryFont:"jaf-bernino-sans-condensed", sans-serif
$fontBaseColor: #000
$fontSecondaryColor: #89959a
$fontThirdColor: #d0d0d0
$linkColor: #89959a

$background: #fff
$siteWidth: 1400px


$xmobile: 460px
$mobile: 640px
$xtablet: 760px
$tablet: 860px
$netbook: 1024px
$retina: 1440px


/*** MIXINS ***/
@mixin clearfix
  &:after
    clear: both
    content: ""
    display: block
    visibility: hidden

@mixin wrapper($width)
  display: block
  position: relative
  width: 100%
  max-width: $width
  margin: 0 auto
  padding: 0 20px
  +clearfix

@mixin justified
  &:after
    content: ""
    width: 100%
    display: inline-block
    *zoom: 1

@mixin fluid-image
  position: absolute
  min-width: 100%
  min-height: 100%
  left: -50%
  right: -50%
  top: -1%
  bottom: -1%
  margin: auto
  +backface-visibility(hidden)
  +transform(translateZ(0) scale(1))
  +transition(all 150ms ease-out)

@mixin blur 
  filter: blur(5px)
  -webkit-filter: blur(5px)
  -moz-filter: blur(5px)
  -o-filter: blur(5px)
  -ms-filter: blur(5px)
  filter: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQoJPGZpbHRlciBpZD0iYmx1ciI+DQoJCTxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjMiIC8+DQoJPC9maWx0ZXI+DQo8L3N2Zz4=#blur)

@mixin media-query($media-query)
  @media only screen and(max-width:$media-query)
    @content


@mixin numbered-list
  &::before
    counter-increment: list
    content: counter(list)
    color: inherit
    font-weight: 400
    display: inline-block
    position: absolute
    left: 0