.photo
	display: block
	margin: 15px 25px
	position: relative
	z-index: 99
	color: #000
	&--left
		float: left
		margin-left: 0px
	&--right
		float: right
		margin-right: 0px
	&--center
		text-align: center
	&--wide
		margin: 15px 0
		width: 100%
		figure, .litebox, img
			width: 100%
			
	figure
		display: table
		position: relative
		overflow: hidden
		margin: auto
		figcaption
			display: table-caption
			caption-side: bottom
			padding: 7px 0 5px 0
			left: 0
			right: 0
			bottom: 0
			font-size: 0.8em
			text-align: left
			line-height: 1.4
			+transition(all 150ms linear)
			p 
				font-size: 12px
				font-weight: 400
				font-style: italic

		.litebox
			display: block
			background: #000
			text-align: center
			img
				max-width: 100%
				+transition(opacity 150ms linear)

		&:hover
			.litebox::after
				content: url(../images/icons/zoom.png)
				position: absolute
				top: 0 
				bottom: 0
				left: 0
				right: 0
				margin: auto
				height: 46px
				z-index: 999
			.litebox
				img
					opacity: 0.5
				+ figcaption
					top: 100%
					

+media-query($tablet)
	.photo
		&--left, &--right
			max-width: 50%
			figure .litebox img
				width: 100%


+media-query(500px)
	.photo
		width: 100%
		max-width: none
		margin: 20px 0
		figure .litebox img
			width: 100%