// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes overlay {
  0% {-webkit-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 150px; width: 150px; opacity: 0.3; left: -150px; right: -150px;}
}

@-moz-keyframes overlay {
  0% {-moz-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 150px; width: 150px; opacity: 0.3; left: -150px; right: -150px;}
}

@-ms-keyframes overlay {
  0% {-ms-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 150px; width: 150px; opacity: 0.3; left: -150px; right: -150px;}
}

@-o-keyframes overlay {
  0% {-o-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 150px; width: 150px; opacity: 0.3; left: -150px; right: -150px;}
}

@keyframes overlay {
  0% {transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 150px; width: 150px; opacity: 0.3; left: -150px; right: -150px;}
}



// originally authored by Nick Pettit - https://github.com/nickpettit/glide
@-webkit-keyframes overlayBig {
  0% {-webkit-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 250px; width: 250px; opacity: 0.3; left: -250px; right: -250px;}
}

@-moz-keyframes overlayBig {
  0% {-moz-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 250px; width: 250px; opacity: 0.3; left: -250px; right: -250px;}
}

@-ms-keyframes overlayBig {
  0% {-ms-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 250px; width: 250px; opacity: 0.3; left: -250px; right: -250px;}
}

@-o-keyframes overlayBig {
  0% {-o-transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 250px; width: 250px; opacity: 0.3; left: -250px; right: -250px;}
}

@keyframes overlayBig {
  0% {transform: scale(1);}
  /* 50% {height: 150px; width: 150px; opacity: 0.5} */
  100% {height: 250px; width: 250px; opacity: 0.3; left: -250px; right: -250px;}
}


@mixin overlay($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(overlay);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}


@mixin overlayBig($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(overlayBig);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
