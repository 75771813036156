@-webkit-keyframes slideInDown {
	0% {opacity: 0; -webkit-transform: translateY(-2000px);}
	100% {opacity: 1; -webkit-transform: translateY(0);}
}

@-moz-keyframes slideInDown {
	0% {opacity: 0; -moz-transform: translateY(-2000px);}
	100% {-moz-transform: translateY(0);}
}

@-ms-keyframes slideInDown {
	0% {opacity: 0; -ms-transform: translateY(-2000px);}
	100% {-ms-transform: translateY(0);}
}

@-o-keyframes slideInDown {
	0% {opacity: 0; -o-transform: translateY(-2000px);}
	100% {-o-transform: translateY(0);}
}

@keyframes slideInDown {
	0% {opacity: 0; transform: translateY(-2000px);}
	100% {opacity: 1; transform: translateY(0);}
}

@mixin slideInDown($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(slideInDown);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
