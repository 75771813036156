// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes pulse {
  0% {-webkit-transform: scale(1);}
  50% {-webkit-transform: scale(1.1);}
  100% {-webkit-transform: scale(1);}
}

@-moz-keyframes pulse {
  0% {-moz-transform: scale(1);}
  50% {-moz-transform: scale(1.1);}
  100% {-moz-transform: scale(1);}
}

@-ms-keyframes pulse {
  0% {-ms-transform: scale(1);}
  50% {-ms-transform: scale(1.1);}
  100% {-ms-transform: scale(1);}
}

@-o-keyframes pulse {
  0% {-o-transform: scale(1);}
  50% {-o-transform: scale(1.1);}
  100% {-o-transform: scale(1);}
}

@keyframes pulse {
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

@mixin pulse($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(pulse);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
