.buy-prints
	display: table
	table-layout: fixed
	&__col
		display: table-cell
		vertical-align: top
		width: 50%
		padding: 50px 20px 0 20px

+media-query(1600px)
	.buy-prints
		padding: 0 70px
+media-query($netbook)
	.buy-prints
		padding: 0 70px
		&__col
			width: 100%
			padding: 0 20px 0 20px
			&:first-child
				display: none
+media-query($mobile)
	.buy-prints
		padding: 0 !important
	.site-cms
		padding: 100px 0
		.site-wrapper
			padding: 0 30px
