// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@-webkit-keyframes wobble {
  0% {-webkit-transform: translateX(0%);}
  15% {-webkit-transform: translateX(-25%) rotate(-5deg);}
  30% {-webkit-transform: translateX(20%) rotate(3deg);}
  45% {-webkit-transform: translateX(-15%) rotate(-3deg);}
  60% {-webkit-transform: translateX(10%) rotate(2deg);}
  75% {-webkit-transform: translateX(-5%) rotate(-1deg);}
  100% {-webkit-transform: translateX(0%);}
}

@-moz-keyframes wobble {
  0% {-moz-transform: translateX(0%);}
  15% {-moz-transform: translateX(-25%) rotate(-5deg);}
  30% {-moz-transform: translateX(20%) rotate(3deg);}
  45% {-moz-transform: translateX(-15%) rotate(-3deg);}
  60% {-moz-transform: translateX(10%) rotate(2deg);}
  75% {-moz-transform: translateX(-5%) rotate(-1deg);}
  100% {-moz-transform: translateX(0%);}
}

@-ms-keyframes wobble {
  0% {-ms-transform: translateX(0%);}
  15% {-ms-transform: translateX(-25%) rotate(-5deg);}
  30% {-ms-transform: translateX(20%) rotate(3deg);}
  45% {-ms-transform: translateX(-15%) rotate(-3deg);}
  60% {-ms-transform: translateX(10%) rotate(2deg);}
  75% {-ms-transform: translateX(-5%) rotate(-1deg);}
  100% {-ms-transform: translateX(0%);}
}

@-o-keyframes wobble {
  0% {-o-transform: translateX(0%);}
  15% {-o-transform: translateX(-25%) rotate(-5deg);}
  30% {-o-transform: translateX(20%) rotate(3deg);}
  45% {-o-transform: translateX(-15%) rotate(-3deg);}
  60% {-o-transform: translateX(10%) rotate(2deg);}
  75% {-o-transform: translateX(-5%) rotate(-1deg);}
  100% {-o-transform: translateX(0%);}
}

@keyframes wobble {
  0% {transform: translateX(0%);}
  15% {transform: translateX(-25%) rotate(-5deg);}
  30% {transform: translateX(20%) rotate(3deg);}
  45% {transform: translateX(-15%) rotate(-3deg);}
  60% {transform: translateX(10%) rotate(2deg);}
  75% {transform: translateX(-5%) rotate(-1deg);}
  100% {transform: translateX(0%);}
}

@mixin wobble($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(wobble);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
