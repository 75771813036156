@-webkit-keyframes slideInRight {
	0% {-webkit-transform: translateX(2000px);}
	100% {-webkit-transform: translateX(0);}
}

@-moz-keyframes slideInRight {
	0% {-moz-transform: translateX(2000px);}
	100% {-moz-transform: translateX(0);}
}

@-ms-keyframes slideInRight {
	0% {-ms-transform: translateX(2000px);}
	100% {-ms-transform: translateX(0);}
}

@-o-keyframes slideInRight {
	0% {-o-transform: translateX(2000px);}
	100% {-o-transform: translateX(0);}
}

@keyframes slideInRight {
	0% {transform: translateX(2000px);}
	100% {transform: translateX(0);}
}

@mixin slideInRight($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(slideInRight);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
