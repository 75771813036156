@include keyframes(bounceInUp) {
  0% {opacity: 0; -webkit-transform: translateY(2000px);}
  60% {opacity: 1; -webkit-transform: translateY(-30px);}
  80% {-webkit-transform: translateY(10px);}
  100% {-webkit-transform: translateY(0);}
}

@mixin bounceInUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(bounceInUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
