.galleries
  display: block
  position: relative
  max-width: 1200px
  margin: auto
  +clearfix
  &__content
    display: block
    margin: -2%
    font-size: 0
    +clearfix
  &__gallery-thumb
    position: relative
    display: inline-block
    vertical-align: top
    width: 46%
    margin: 2% 2% 2% 2%
    background-size: cover
    cursor: pointer
    &:hover
      .gallery-thumb
        &__image
          img
            opacity: 0.4
            +transform(scale(1.1))
        &__title
          color: #ddd
     
  .gallery-thumb
    &__title
      display: block
      padding: 20px
      font-family: "jaf-bernino-sans-condensed"
      font-size: 32px
      font-weight: 100
      text-align: center
      +transition(color 300ms ease-in-out)
    &__image
      position: relative
      display: block
      width: 100%
      height: 100%
      background-color: #000
      overflow: hidden
      img
        margin: auto
        width: 100%
        overflow-x: hidden
        +transition(all 300ms ease-in-out)

            
+media-query($retina)
  .galleries
    padding: 0 50px

+media-query(1300px)
  .galleries
    padding: 0 100px

+media-query($tablet)
  .galleries
    padding: 0 40px
    text-align: center
    &__gallery-thumb
      width: 100%
      max-width: 500px
      margin: 0
      margin-bottom: 20px
      .gallery-thumb
        &__title
          padding: 10px 0
          font-size: 26px