.gallery
  display: block
  position: relative
  &__list
    position: relative
    margin-top: -120px
    z-index: 100
  &__background--no-blur, &__background--blur
    opacity: 0
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    background-color: #000
    z-index: 6
    opacity: 1
  // &__background--no-blur
  //   +fadeIn(1, 3000ms, 500ms)
  // &__background--blur
  //   +fadeIn(1, 0ms, 4000ms)

  &__background--blur
    z-index: 5
  &__background--image
    display: block
    width: 100%
    height: 100%
    background-position: top center
    background-repeat: no-repeat
    background-size: cover
    background-attachment: fixed
    opacity: 0.4
.scrollTo
  display: inline-block
  margin-top: 70px
  cursor: pointer

+media-query($tablet)
  .gallery
    &__list
      margin-top: -60px