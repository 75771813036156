.gallery
  display: block
  margin: 40px 0
  padding: 40px 0
  .cms &
    .gallery__image
      width: 31.3%
  &__images
    display: block
    position: relative
    margin: -1%
    +clearfix
  &__image
    display: block
    width: 23%
    position: relative
    padding: 2% 0
    background: #000
    margin: 1%
    float: left
    overflow: hidden
    &::after
      content: ""
      display: block
      padding-bottom: 56.25%
    figure
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      margin: auto
      figcaption
        position: absolute
        display: block
        padding: 7px 10px 5px 10px
        left: 0
        right: 0
        bottom: 0
        background: rgba(0, 0, 0, 0.2)
        text-align: left
        +transition(all 150ms linear)
        p 
          font-size: 12px
          font-weight: 600
          text-transform: uppercase
          color: #fff
          text-shadow: 1px 1px 15px #000;

      .litebox
        display: block
        width: 100%
        height: 100%
        background: #000
        text-align: center
        img
          +transition(opacity 150ms linear)

      img
        display: block
        position: absolute
        min-width: 100%
        min-height: 100%
        max-width: none
        left: -50%
        right: -50%
        top: -1%
        bottom: -1%
        margin: auto
        opacity: 1
        backface-visibility: hidden
        -webkit-backface-visibility: hidden
        +transform(translateZ(0))
      
      &:hover
        .litebox::after
          content: "+"
          font-size: 100px
          font-weight: 100
          position: absolute
          top: 0 
          bottom: 0
          left: 0
          right: 0
          margin: auto
          height: 100px
          color: #fff
          z-index: 999
          line-height: 1
        .litebox
          img
            opacity: 0.4
          + figcaption
            top: 100%
            

+media-query(960px)
  .gallery
    &__image
      width: 31%

+media-query(760px)
  .gallery
    &__image
      width: 48% !important

+media-query(500px)
  .gallery
    &__image
      width: 100% !important
      max-width: 300px
      margin: 10px auto
      float: none