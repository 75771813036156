.modal
	display: block
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	opacity: 0
	z-index: 99999 !important
	+transition(opacity 300ms linear)
	&-dialog, &-content
		display: block
		width: 100%
		height: 100%

.modal-backdrop
	position: fixed
	top: 0
	bottom: 0
	left: 0
	right: 0
	background-size: cover
.modal.fade.in
	opacity: 1