.promo-pictures
  position: relative
  display: block
  width: 100%
  max-width: $siteWidth
  margin: 0 auto
  +transition(all 500ms linear)
  &__picture
    position: absolute
    display: block
    text-align: center
    left: 0
    top: 0
    +transform(translate3d(0px, 0px, 0px))
    +transition(transform 0ms linear 0ms, left 0ms linear 0ms, top 0ms linear 0ms, opacity 1000ms linear 500ms)
    .promo-picture__link
      width: 100%
      min-height: 200px
      background: url(../images/spinner.gif) center center no-repeat
      &.hidden
        opacity: 0 !important
    &:hover
      .promo-picture
        &__gallery
          bottom: 30px
          opacity: 1
        &__link::after
          bottom: 0
          opacity: 1
    
    &.newPicture
      .promo-picture__link::before
        content: "new"
        display: block
        width: 35px
        height: 35px
        background: $secondaryColor
        position: absolute
        top: 10%
        right: -15px
        font-family: $secondaryFont
        font-size: 16px
        font-weight: 600
        text-align: center
        color: #fff
        line-height: 46px
        z-index: 40
    
    img
      position: relative
      display: block
      width: 100%
      z-index: 10
      +fadeInUp(300ms)

  .promo-picture
    &__link
      display: block
      position: relative
      &::after
        content: url(../images/icons/loop.png)
        opacity: 0
        position: absolute
        top: 0
        bottom: 10px
        left: 0
        right: 0
        width: 58px
        height: 58px
        margin: auto
        z-index: 30
        +transition(all 300ms linear)

    &__gallery 
      opacity: 0
      position: absolute
      bottom: 20px
      left: 20px
      right: 20px
      font-size: 14px
      font-weight: 300
      text-align: center
      color: #fff
      z-index: 20
      +transition(all 300ms linear)
      a
        font-weight: 600
        text-decoration: underline
        color: inherit
        &:hover
          color: $secondaryColor


.masonry-container.transition
  .promo-pictures__picture
    +transition(all 1000ms linear 1000ms)

#large-image
  display: block !important
  position: fixed
  top: 0
  left: 0
  margin: auto
  width: auto
  background: url(../images/icons/svg/spinner.svg) center center no-repeat
  background-color: rgba(0, 0, 0, 0.8)
  cursor: pointer
  z-index: 999999


  &.hidden
    visibility: hidden
  &.layer-bottom
    z-index: 9999

.full-screen
  position: absolute
  background: url(../images/icons/svg/spinner.svg) center center no-repeat
  background-color: rgba(0, 0, 0, 0.8)
  z-index: 999999999
  overflow: hidden
  img
    opacity: 0
    +transition(all 500ms linear)
    &.vertical
      width: 100%
      height: auto
    &.horizontal
      width: auto
      height: 100%
  img.full-screen__image
    display: block
    opacity: 1 !important
    
  .close-view
    display: inline-block
    position: fixed
    top: 50px
    right: 50px
    background: transparent
    font-size: 36px
    color: #fff
    z-index: 9999

#scroller
  position: absolute
  z-index: 1
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  width: 100%
  -webkit-transform: translateZ(0)
  -moz-transform: translateZ(0)
  -ms-transform: translateZ(0)
  -o-transform: translateZ(0)
  transform: translateZ(0)
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-text-size-adjust: none
  -moz-text-size-adjust: none
  -ms-text-size-adjust: none
  -o-text-size-adjust: none
  text-size-adjust: none
  &.horizontal
    position: static
    width: auto
    height: 100% !important

.iScrollIndicator
  border: none !important
  border-radius: 0px !important
  background: rgba(255,255,255,0.6) !important