@include keyframes(fadeOutUp) {
  0% {opacity: 1; transform: translateY(0); -webkit-transform: translateY(0); -ms-transform: translateY(0);}
  50% {opacity: 0.4;}
  100% {opacity: 0; transform: translateY(-200px); -webkit-transform: translateY(-200px); -ms-transform: translateY(-200px);}
}

@mixin fadeOutUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOutUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
