.pagination
	display: block
	margin: 10px 0
	padding: 10px 0
	font-size: 0
	font-weight: 600
	.numbers
		display: inline-block
		margin: 0
		padding: 0
		font-size: 12px
		font-weight: 400
		text-align: right
		li
			display: inline-block
			vertical-align: middle
			padding: 0 3px !important
			&::before
				display: none !important

			a
				padding: 3px
				color: #fff
				&.current
					font-weight: 900
					color: #bbb
				&:hover
					color: #bbb

		li::before
			display: none !important

	.sites
		display: inline-block
		width: 25%
		vertical-align: middle
		font-size: 14px
		font-weight: 400

	.numbers
		display: inline-block
		width: 100%

+media-query(800px)
	.pagination .sites
		display: none

	.pagination .numbers
		width: 100%
		text-align: center

		