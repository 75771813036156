@include keyframes(fadeInUp) {
  0% {opacity: 0; transform: translateY(20px); -webkit-transform: translateY(20px); -ms-transform: translateY(20px);}
  100% {opacity: 1; transform: translateY(0); -webkit-transform: translateY(0); -ms-transform: translateY(0);}
}

@mixin fadeInUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
