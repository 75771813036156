.site-header
  position: fixed
  top: 0
  width: 100%
  z-index: 9999
  &--gallery
    position: relative
    height: 85vh
    margin-top: -150px
    color: #fff
    z-index: 9
  &--picture
    display: block
    height: 100px
    .site-header__content
      display: block !important
  &-loader
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    background: #222
    z-index: 10
  section
    display: table
    width: 100%
    position: relative
    z-index: 10
    .site-logo, nav
      display: table-cell
      vertical-align: middle
  .site-logo
    width: 160px
    img, svg
      width: 100%
      fill: #000
    .site--gallery &
      svg
        fill: #fff
  nav
    ul
      display: block
      height: 100%
      text-align: right
      li
        display: inline-block
        vertical-align: middle
        padding: 0
        &.close-view
          a
            font-size: 30px
        &::before
          display: none
        a, span, button
          padding: 5px
          font-size: 14px
          line-height: 1
        a:hover
          color: #aaa
        button
          font-size: 36px
          background: transparent
          +transition(200ms)
          &:hover
            +transform(rotate(90deg))
  
  &__content
    display: block
    padding: 15px 45px 5px 45px
    background: rgba(255, 255, 255, 0)
    section
      padding: 15px 0 10px 0

  &__title
    position: absolute
    bottom: 23%
    left: 0
    right: 0
    margin: auto
    text-align: center
    z-index: 10
    h1
      margin-bottom: 80px
      font-family: $secondaryFont
      font-size: 100px
      font-weight: 900
      text-transform: uppercase
    p
      max-width: 500px
      margin: 0 auto
      font-family: $baseFont
      font-size: 26px
      font-weight: 300
      line-height: 1.8
    
// .base-view--galleries .site-header__content
//   background-color: transparent !important


.mobile-menu
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  color: #000
  background: rgba(255, 255, 255, 0.9)
  z-index: 9999
  opacity: 0
  overflow: hidden
  &.open
    +animation(fadeInMenu 400ms)
    display: table
    opacity: 1
    ul
      +animation(fadeInMenuItems 400ms)
      opacity: 1
      +transform(scale(1))
  &.close
    +animation(fadeOutMenu 400ms)
    // display: table
    ul
      +animation(fadeOutMenuItems 400ms)
    
  ul
    display: table-cell
    vertical-align: middle
    padding: 40px
    text-align: center
    opacity: 0
    +transform(scale(1.5))
    +transition(all 400ms linear 200ms)
    li
      position: relative
      margin: 0
      padding: 10px 0
      font-size: 42px
      font-weight: 700
      text-transform: uppercase
      &::before
        display: none
      a
        position: relative
      a::after
        content: ""
        position: absolute
        bottom: 5px
        left: 0
        right: 0
        width: 0
        height: 4px
        margin: auto
        background: #000
        +transition(width 200ms ease-in-out)
      &:hover
        a::after
          width: 100%

+keyframes(fadeInMenu)
  0%
    display: table
    opacity: 0
  100%
    display: table
    opacity: 1
+keyframes(fadeOutMenu)
  0%
    display: table
    opacity: 1
  99%
    display: table
    opacity: 0
  100%
    display: none
    opacity: 0


+keyframes(fadeInMenuItems)
  0%
    opacity: 0
    +transform(scale(1.8))
  100%
    opacity: 1
    +transform(scale(1))
+keyframes(fadeOutMenuItems)
  0%
    opacity: 1
    +transform(scale(1))
  100%
    opacity: 0
    +transform(scale(1.8))



+media-query($retina)
  .site-header
    &__title
      bottom: 16%
+media-query(800px)
  .site-header
    &__title
      bottom: 30%
      h1
        margin-bottom: 30px
        font-size: 60px
      p
        font-size: 20px
    .scrollTo
      margin-top: 45px

+media-query($tablet)
  .site-header
    &--gallery
      height: 100vh
    &__title
      bottom: 22%
      padding: 0 10px
+media-query($mobile)
  .site-header
    &__content
      padding: 10px 25px !important
    section
      display: block
      .site-logo
        display: none
        .base-view--galleries &, .modal-open &
          display: inline-block
        img
          width: 100px !important
      nav
        display: none
        .base-view--galleries &, .modal-open &
          display: inline-block
          float: right
