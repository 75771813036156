@include keyframes(flipInX) {
  0% {transform: perspective(400px) rotateX(90deg); opacity: 0;}
  40% {transform: perspective(400px) rotateX(-10deg);}
  70% {transform: perspective(400px) rotateX(10deg);}
  100% {transform: perspective(400px) rotateX(0deg); opacity: 1;}
}

@mixin flipInX($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(flipInX);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
