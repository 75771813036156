.site-footer
	position: relative
	display: block
	position: fixed
	bottom: 20px
	left: 0
	width: 100%
	z-index: 100
	.made-by
		position: absolute
		right: 0
		+transform(rotate(-90deg))
		+transform-origin(5% -145%)
	.infos
		position: absolute
		left: 0
		+transform(rotate(-90deg))
		+transform-origin(5% -145%)
	p, a, ul, li
		font-size: 14px
	a
		text-decoration: underline
.base-view--galleries + .site-footer
	.made-by, .infos
		color: #fff

+media-query($netbook)
	.site-footer
		.made-by
			+transform-origin(25% -145%)
		.infos
			+transform-origin(5% -65%)

+media-query($tablet)
	.site-footer
		position: absolute
		top: 100%
		bottom: auto
		.made-by, .infos
			position: static
			display: block
			width: 100%
			margin-bottom: 20px
			text-align: center
			+transform(rotate(0deg))
			+transform-origin(0% 0%)
		