.cookies
	display: none
	position: fixed
	bottom: 0
	left: 0
	right: 0
	margin: auto
	background: #008fcb
	color: #fff
	z-index: 99999
	&__container
		display: table
		position: relative
		width: 100%
		margin: 0 auto
		padding: 15px 0
		text-align: left
		// background: url(../images/cookies.jpg) left center no-repeat
		background-size: contain
	&__name
		display: table-cell
		vertical-align: top
		p
			font-size: 16px
			text-transform: uppercase
	&__information
		display: table-cell
		vertical-align: middle
		padding-left: 25%
		padding-right: 20px
		color: #fff
		p, a, ul, li
			font-size: 10px
		a
			color: #fff
	&__button-set
		display: table-cell
		vertical-align: middle
		white-space: nowrap

+media-query(960px)
	.cookies
		&__information
			padding-left: 20px
+media-query(740px)
	.cookies
		&__container
			display: block
		&__name, &__information, &__button-set
			display: block
			width: 100%
			margin-bottom: 10px
			text-align: center
			white-space: initial
			.button
				margin: 5px