.article
  display: block
  .section__wrapper
    background: #fff
    padding: 40px
    max-width: 1160px

  &__content
    display: block
    float: left
    margin: 1% 0
    width: 100%
  &__title
    display: block
    margin: 0
    padding: 0
    margin-bottom: 5px
    text-transform: none
    line-height: 1.2
    color: #333
    a
      color: #333
      &:hover
        color: $colorRed
  &__text
    display: block
    *
      font-size: 15px
      line-height: 1.4
  &__more
    display: block
    margin: 5px 0
    text-align: right
    a
      font-size: 13px
      font-weight: 600
      color: #000
      &:hover
        color: $colorRed

  &__title ~ .article__text, &__date ~ .article__text
    margin-top: 15px


.article__top-bar
  display: block
  margin-bottom: 20px
  padding-bottom: 20px
  border-bottom: 1px #ddd solid
  font-size: 0
  .button-back, .article__date
    display: inline-block
    vertical-align: bottom
    width: 50%
    font-size: 14px
    p
      font-family: "proxima-nova"
      font-size: 18px
      font-weight: 100
      font-style: italic
      color: #eee
  .button-back
    position: relative
    a
      display: inline-block
      padding: 10px 15px
      font-weight: 600
      color: #fff
      background: $colorRed
      border: 3px transparent solid
      +transition(all 150ms linear)
      &:hover
        background: #fff
        color: $colorRed
        border-color: $colorRed
  .article__date
    text-align: right
    