.share-link
  display: none
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  z-index: 99999
  text-align: center
  &.fadeIn
    .share-link-box__container
      opacity: 1
      +fadeInDown(200ms)
  &.fadeOut
    .share-link-box__container
      opacity: 1
      +fadeOutDown(200ms)
  &__overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.85)
    z-index: 10
  
  &__buttons
    display: inline-block
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    width: 500px
    height: 150px
    z-index: 20
    .share-button
      display: inline-block
      width: 150px
      height: 150px
      margin: 0 5px
      cursor: pointer
      position: relative
      text-align: center
      +perspective(1000px)
      &:hover
        .share-button
          &__front
            z-index: 900
            +transform(rotateY(180deg))
          &__back
            z-index: 1000
            +transform(rotateX(0deg) rotateY(0deg))
      img
        width: 100%
      .share-button
        &__link
          display: block
          width: 100%
          height: 100%
          font-size: 40px
          line-height: 70px
          &--fb
            color: #395999
          &--twitter
            color: #00bbf4
          &--in
            color: #0082cc
          &--youtube
            color: #ff0000
        &__front
          display: block
          width: 100%
          height: 100%
          background: #000
          +transform(rotateX(0deg) rotateY(0deg))
          +transform-style(preserve-3d)
          +backface-visibility(hidden)
          +transition(all 150ms linear)
        &__back
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          background: #fff
          z-index: 800
          +transform(rotateY(-180deg))
          +transform-style(preserve-3d)
          +backface-visibility(hidden)
          +transition(all 150ms linear)
          *
            color: #fff !important

  
  

  &__close
    position: absolute
    top: -50px
    right: -50px
    width: 45px
    height: 45px
    padding: 8px
    font-size: 24px
    font-weight: 100
    text-align: center
    color: #000
    line-height: 30px
    background: #fff
    &:hover
      color: #fff
      background: #000


+media-query($tablet)
  .share-link
    &__buttons
      height: auto
      width: auto
      top: 50px
      padding-top: 35px
    &__close
      top: -30px
      right: 30px
