html, body
  height: 100%
body
  display: block
  position: relative
  min-height: 100%
  font-family: $baseFont
  color: $fontBaseColor
  background: #fff
  background-image: none
  background-position: top center
  background-size: cover
  // background-attachment: fixed
  line-height: 1.6250em
  *
    margin: 0
    padding: 0
    max-width: none
    max-height: none
    border: 0px
    box-sizing: border-box
    outline: none
    text-decoration: none
    color: inherit


.base-view
  display: block
  min-height: 100vh
  padding: 150px 0 100px 0
  // background-attachment: fixed
  &::before
    content: ""
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: #fff
    background-image: none
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

  &--main, &--default, &--gallery
    &::before 
      background-image: url(../images/bg.jpg)
  &--gallery-awward
    &::before
      background-image: url(../images/bg-red.jpg)
  &--galleries
    color: #fff
    &::before
      background-image: url(../images/galleries.jpg)
  

p, ul, ol
  font-family: "jaf-bernino-sans-condensed"
  font-size: 14px
  font-weight: 400
  color: inherit
  line-height: 1.6em

p
  min-height: 1.4em

strong
  font-weight: 600

h1, h2, h3, h4 , h5, h6
  margin-bottom: 0
  font-family: $secondaryFont
  line-height: 1
h1
  font-size: 72px
h2
  font-size: 60px
h3
  font-size: 48px
h4
  font-size: 30px
h5
  font-size: 24px
h6
  font-size: 18px

ul, li, a 
  margin: 0
  padding: 0
  border: 0
  list-style: none
  line-height: 1
  text-decoration: none
  position: relative
a 
  line-height: 1.3
  color: inherit
  // &:hover
  //   color: $fontSecondaryColor
img
  display: block
ul li
  margin-bottom: 10px
  padding-left: 20px
  line-height: 1.4
  &::before
    content: ""
    width: 10px
    height: 2px
    background: $fontSecondaryColor
    margin-right: 10px
    position: absolute
    top: 0.6em
    left: 0px
    font-family: FontAwesome
    display: inline-block
    vertical-align: middle
  li::before
    display: none
    visibility: hidden      
      
ol
  counter-reset: list
  > li
    margin-bottom: 5px
    padding-left: 20px
    line-height: 1.4
    +numbered-list
    &:nth-child(10n) ~ li::before,
    &:nth-child(10n)::before
      content: counter(list)

table
  margin-bottom: 30px
  thead
    th, td
      padding: 10px !important
      font-weight: 600
      font-size: 16px
      font-weight: 600
      text-align: left
      border-bottom: 2px #ccc solid
  tbody
    td
      padding: 15px 10px
      font-weight: 400
      line-height: 1.2
      border-bottom: 1px #ccc solid
      &::before
        background: #eee
        color: #000414
    
form
  display: block
  margin: 0 -10px
  +clearfix
  legend
    display: block
    width: 100%
    margin-bottom: 40px
    font-size: 36px
    font-weight: 600
  fieldset
    position: relative
    margin-bottom: 15px
    &.submit
      text-align: center
      
  label
    display: block
    width: 100%
    font-size: 14px
    font-weight: 600
  input, select, textarea
    display: block
    width: 100%
    padding: 10px
    border: 1px #ddd solid
    resize: none
    &.error
      border-color: rgba(255, 0, 0, 0.5)
  textarea
    height: 125px
  input[type="submit"]
    display: inline-block
    width: auto
    font-size: 12px
    color: #fff
    background: #000
    &:hover
      background: #222
    &:active
      background: #111
  
  .fieldset
    display: block
    &--submit
      padding: 10px 0
      text-align: right
      
  .form__column
    width: 50%
    float: left
    padding: 0 10px
    &--wide
      width: 100%

div.error
  display: none !important


.contact-form-content
  .success
    color: rgb(26, 81, 26)
  .error
    color: rgb(140, 1, 1)


.form-message
  display: none
  position: fixed
  top: 55px
  left: 0
  right: 0
  overflow: hidden
  .send-info
    display: block
    position: relative
    width: 100%
    padding: 20px
    font-size: 14px
    text-align: center
    text-transform: uppercase
    color: #fff
    background: #133f81
    overflow: hidden
    &.error
      border-color: red
    &.success
      border-color: green
    p, a, span
      font-size: 14px
      color: #fff
    a
      text-decoration: underline
  .js-close-form-message
    display: inline-block
    position: absolute
    top: 15px
    bottom: 15px
    right: 15px
    margin: auto
    padding: 5px
    font-size: 26px
    font-style: normal
    color: #fff
    cursor: pointer
    background: transparent

.site-preloader
  display: block
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #fff
  z-index: 999999999
  &__content
    width: 200px
    height: 200px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto

  img
    display: block
  .spinner 
    margin: 20px auto 0
    width: 70px
    text-align: center
  

  .spinner > div 
    width: 18px
    height: 18px
    background-color: #000

    border-radius: 100%
    display: inline-block
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out
    animation: bouncedelay 1.4s infinite ease-in-out
    -webkit-animation-fill-mode: both
    animation-fill-mode: both
  

  .spinner .bounce1 
    -webkit-animation-delay: -0.32s
    animation-delay: -0.32s
  

  .spinner .bounce2 
    -webkit-animation-delay: -0.16s
    animation-delay: -0.16s
  

+keyframes(bouncedelay) 
  0%, 80%, 100% 
    -webkit-transform: scale(0.0) 
  40% 
    -webkit-transform: scale(1.0) 


+keyframes(bouncedelay)
  0%, 80%, 100%  
    transform: scale(0.0)
    -webkit-transform: scale(0.0)
  40%  
    transform: scale(1.0)
    -webkit-transform: scale(1.0)


#site-layout
  // position: relative
  width: 100%
  min-height: 100vh
  > div
    width: 100%
  
  #content
    position: relative
    display: block
    width: 100%

.site-cms
  min-height: 100vh
  padding: 150px 0 100px 0
  background: url(../images/bg.jpg) top center no-repeat
  background-size: cover
  background-attachment: fixed
  &--thin
    .site-wrapper
      max-width: 900px


.site-wrapper
  +wrapper($siteWidth)
+media-query(1600px)
  .site-wrapper
    padding: 0 100px
+media-query($mobile)
  .site-wrapper
    padding: 50px 70px 0 70px
      

[ui-view].ng-enter, [ui-view].ng-leave 
  position: absolute
  left: 0
  right: 0
  width: 100%
  // &.picture
  //   position: static
    
  


[ui-view].ng-enter
  display: block
  opacity: 0
  -webkit-transition: opacity .5s ease-in-out 1s
  -moz-transition: opacity .5s ease-in-out 1s
  -o-transition: opacity .5s ease-in-out 1s
  transition: opacity .5s ease-in-out 1s
 
[ui-view].ng-enter
  &.galleries-gallery-view
    .galleries__content.outDown &
      +fadeIn(0ms)
      .galleries__base-picture
        +fadeInDown(1, 400ms, 300ms)
      .galleries__secondary-picture
        +fadeInDown(1, 400ms, 400ms)
      .galleries__show
        +fadeInDown(1, 500ms, 490ms)
      .galleries__description
        +fadeInDown(1, 450ms, 450ms)
    .galleries__content.outUp &
      +fadeIn(0ms)
      .galleries__base-picture
        +fadeInUp(1, 400ms, 300ms)
      .galleries__secondary-picture
        +fadeInUp(1, 400ms, 400ms)
      .galleries__show
        +fadeInUp(1, 500ms, 490ms)
      .galleries__description
        +fadeInUp(1, 450ms, 450ms)




[ui-view].ng-enter-active 
  display: block
  opacity: 1


[ui-view].ng-leave
  opacity: 1
  width: 100%
  -webkit-transition: opacity .5s ease-in-out
  -moz-transition: opacity .5s ease-in-out
  -o-transition: opacity .5s ease-in-out
  transition: opacity .5s ease-in-out
  &.galleries-gallery-view
    .galleries__content.outDown &
      .galleries__base-picture
        +fadeOutDown(1, 400ms)
      .galleries__secondary-picture
        +fadeOutDown(1, 400ms, 100ms)
      .galleries__show
        +fadeOutDown(1, 500ms, 190ms)
      .galleries__description
        +fadeOutDown(1, 450ms, 150ms)
    .galleries__content.outUp &
      .galleries__base-picture
        +fadeOutUp(1, 400ms)
      .galleries__secondary-picture
        +fadeOutUp(1, 400ms, 100ms)
      .galleries__show
        +fadeOutUp(1, 500ms, 190ms)
      .galleries__description
        +fadeOutUp(1, 450ms, 150ms)

  
[ui-view].ng-leave-active 
  display: block
  opacity: 0 !important
  

.scrollbar-dynamic
  height: 100vh
  overflow: auto
  .scroll-x, .scroll-y
    z-index: 99999999 !important
.promo-pictures
  // overflow: hidden
  &__picture
    width: 31.3%
    margin: 1%
    &:nth-child(2)
      margin-top: 100px
  .stamp
    display: none
    position: absolute
    left: 33.3%
    top: 10px
    width: 30%
    height: 100px
    margin: 1%

+media-query(1600px)
  .promo-pictures
    max-width: 1200px !important
+media-query($netbook)
  .base-view
    padding-bottom: 40px
  h1
    font-size: 56px
  .promo-pictures
    &__picture
      width: 46%
      margin: 2% !important

+media-query($mobile)
  .promo-pictures
    padding: 0 40px
    &__picture
      width: 100%
      margin: 0 0 20px 0 !important
      
    