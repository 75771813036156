.order-print-button, .share-link-button, .zoom-image-button
	display: block
	position: relative
	padding: 5px 10px
	padding-left: 35px
	&::before
		content: ""
		width: 20px
		height: 20px
		position: absolute
		left: 5px
		top: 0
		bottom: 0
		margin: auto
.order-print-button
	&::before
		background: url(../images/icons/svg/cart.svg) center left no-repeat !important

.share-link-button
	&::before
		background: url(../images/icons/svg/share.svg) center left no-repeat !important

.zoom-image-button
	&::before
		background: url(../images/icons/svg/zoom-in.svg) center left no-repeat !important


.share-link-btn
	display: block
	width: 300px
	padding: 10px 15px
	text-align: center
	&--fb
		color: #fff
		background: #3a5795
		*
			color: #fff


+media-query($mobile)
	.mobile-hide
		display: none !important
		